import React from 'react'

import content from '../../content/content'

import Layout from '@components/layout'
import SEO from '@components/seo'
import Hero from '@components/Hero'
import Synopsis from '@components/Synopsis'
import Backstory from '@components/Backstory'
import Gallery from '@components/Gallery'
import Crew from '@components/Crew'
import Sales from '@components/Sales'
import Image from '@components/Image'
import Press from '@components/Press'

const IndexPage = () => (
  <Layout>
    <SEO title="" />

    <Hero content={content}/>
    <Synopsis content={content}/>
    <Gallery content={content}/>
    <Backstory content={content}/>
    <Crew content={content}/>
    <Sales content={content}/>
    <Press content={content}/>

  </Layout>
)

const IndexPageTemp = () => {
  return (
    <div className="bg-brand min-h-screen flex">
      <SEO title="" />
      <div className="container m-auto">
        <Image src="9. MyDMposter_final.jpg"/>
      </div>
    </div>
  )
}

export default IndexPage

import React from 'react'

import Image from '@components/Image'

export default function Crew ({ content }) {
  return (
    <section className="flex flex-wrap mt-12 lg:pl-12 xl:pl-40" id="crew">
      <div className="w-full lg:w-1/3 title">
        Crew
      </div>
      <div className="w-full lg:w-2/3">
        {content.crew.map(person => (
          <div key={person.name} className="flex flex-wrap mb-12 xl:-ml-12">
            <div className="w-full lg:mr-4 h-96 lg:w-64 lg:h-44">
              {person.picture && (<Image src={person.picture} className="h-full w-full bg-gray-400"/>)}
              {!person.picture && (<div className="h-full w-full bg-gray-400"/>)}
            </div>
            <div className="w-full lg:flex-1">
              <div className="uppercase text-xl my-6 lg:my-0 lg:mb-4 leading-tight font-roboto-condensed" >{person.name} {person.job && `- ${person.job}`}</div>
              <div className="text-base">{person.text}</div>
            </div>
          </div>
        ))}

      </div>
    </section>
  )
}

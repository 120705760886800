// @flow
import React from 'react'

export default function Sales ({ content }: {content: any}) {
  return (
    <section className="row mt-12" id="sales">
      <div className="col w-full lg:w-1/3 title">
        International Sales
      </div>
      <div className="col w-full lg:w-2/3">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-64 uppercase lg:text-right mr-4">Sales Enquiries</div>
          <div className="w-full lg:flex-1">
            {content.sales.map((paragraph, index) => (
              <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }}/>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

// @flow
import React from 'react'

export default function Synopsis ({ content }: {content: any}) {
  return (
    <section className="row mt-12" id="synopsis">
      <div className="col w-full lg:w-1/3 title">
        Synopsis
      </div>
      <div className="col w-full lg:w-2/3">

        {content.synopsis.map((paragraph, index) => (
          <p className="mb-4" key={index} dangerouslySetInnerHTML={{ __html: paragraph }}/>
        ))}
      </div>
    </section>
  )
}

import React from 'react'

export default function Backstory ({ content }) {
  return (
    <section className="row mt-12" id="backstory">
      <div className="col w-full lg:w-1/3 title">
        Backstory
      </div>
      <div className="col w-full lg:w-2/3">
        {content.backstory.map((paragraph, index) => (
          <p className="mb-4" key={index} dangerouslySetInnerHTML={{ __html: paragraph }}/>
        ))}

        <a href="https://www.gofundme.com/f/education-support-for-the-stolen-generations?utm_medium=copy_link&utm_source=customer&utm_campaign=p_lico+share-sheet"
          target="_blank" rel="noopener noreferrer">
          <div className="w-64 bg-white text-brand font-bold font-roboto-condensed uppercase py-2 text-center mt-8 hover:box-glow-lg">
            Show your support
          </div>
        </a>

      </div>
    </section>
  )
}

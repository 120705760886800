// @flow

import React, { useState, useEffect } from 'react'
import ReactPlayer from 'react-player'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import Image from '@components/Image'

export default function Hero ({ content }: { content: any }) {
  const [playMovie, setPlayMovie] = useState(false)
  const [parallax, setParallax] = useState(0)

  useEffect(() => {
    window.addEventListener('scroll', () => scrollEvent())
    return window.removeEventListener('scroll', () => scrollEvent())
  }, [])

  const scrollEvent = () => {
    const value = (window.pageYOffset * 0.05) * -1
    if (value > -25) {
      setParallax(value)
    }
  }

  return (

    <section className="text-center uppercase font-roboto-condensed pt-10 lg:pt-0">
      <h2 className="mt-12 mb-8 px-6 text-lg sm:text-xl md:text-3xl font-thin text-black" dangerouslySetInnerHTML={{ __html: content.tagline }}/>

      <h1 className="hidden">My Dear Mother</h1>
      <div className="w-full px-12 lg:px-24">
        <Image src="title.png"/>
      </div>

      <h4 className="text-lg md:text-2xl text-black font-thin mt-8" dangerouslySetInnerHTML={{ __html: content.subtitle }}/>

      <div className="hidden lg:flex justify-center font-bold mt-8 text-lg" style={{
        transform: `translate3d(0, ${parallax}px, 0)`,
        transition: 'transform 5ms cubic-bezier(0.1, 0.7, 1.0, 0.1)'
      }}>
        {content.anchors.map((anchor, index) => (
          <div key={index} className="mx-4">
            <AnchorLink offset={50} className="hover:glow" href={anchor.link}>{anchor.label}</AnchorLink>
          </div>
        ))}
      </div>

      <div className="hidden lg:flex justify-center mt-10">
        <a href={content.facebook} target="_blank" rel="noopener noreferrer" className="h-8 w-8 box-glow bg-white rounded-full text-indigo-800 p-1 mx-2">
          <svg className="h-full w-full" stroke="currentColor" fill="currentColor" strokeWidth="0" version="1.2" baseProfile="tiny" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 10h3v3h-3v7h-3v-7h-3v-3h3v-1.255c0-1.189.374-2.691 1.118-3.512.744-.823 1.673-1.233 2.786-1.233h2.096v3h-2.1c-.498 0-.9.402-.9.899v2.101z"/>
          </svg>
        </a>
        <a href={content.instagram} target="_blank" rel="noopener noreferrer" className="h-8 w-8 box-glow bg-white rounded-full text-indigo-800 p-1 mx-2">
          <svg className="h-full w-full" stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
            <rect x="2" y="2" width="20" height="20" rx="5" ry="5"/>
            <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"/>
            <line x1="17.5" y1="6.5" x2="17.5" y2="6.5"/>
          </svg>
        </a>
      </div>

      <div className="mt-10" id="trailer">
        <div onClick={() => setPlayMovie(true)} className={`relative cursor-pointer ${playMovie && 'hidden'}`}>
          <Image src="movietrailer.png"/>
          <div className="absolute inset-0 opacity-75 hover:opacity-100 flex">
            <div className="m-auto text-white h-20 w-20">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 65">
                <path d="M65 32.5C65 14.551 50.449 0 32.5 0 14.551 0 0 14.551 0 32.5 0 50.449 14.551 65 32.5 65 50.449 65 65 50.449 65 32.5M51 33c0 .372-.185.72-.492.93l-24.75 16.874a1.127 1.127 0 01-1.158.066 1.124 1.124 0 01-.6-.995v-33.75a1.124 1.124 0 011.76-.93l24.75 16.876c.305.209.49.557.49.929" fill="currentColor" fillRule="evenodd"/>
              </svg>
            </div>
          </div>
        </div>

        {playMovie && (
          <div className={`embed-responsive aspect-ratio-16/9 ${!playMovie && 'hidden'}`}>
            <ReactPlayer url={content.videoUrl} playing={playMovie} controls/>
            {/* <iframe ref={videoRef} className="embed-responsive-item" src={content.videoUrl}/> */}
          </div>
        )}
      </div>

    </section>
  )
}

// @flow
import React from 'react'

export default function Press ({ content }: { content: any }) {
  return (
    <section className="row mt-12" id="press">
      <div className="col w-full lg:w-1/3 title">
        Press
      </div>
      <div className="col w-full lg:w-2/3">
        {content.press.map((paragraph, index) => (
          <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }}/>
        ))}

      </div>
    </section>
  )
}

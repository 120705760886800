// @flow

import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox'

import 'react-image-lightbox/style.css' // This only needs to be imported once in your app
import { graphql, useStaticQuery } from 'gatsby'

import Image from '@components/Image'

export default function Gallery ({ content }: { content: any }) {
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const imagesQuery = useStaticQuery(graphql`
  query {
    images: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}}) {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid(maxWidth: 1200) {
              src
            }
          }
        }
      }
    }
  }
  `)

  const allImages = imagesQuery.images.edges
    .map(image => image.node.childImageSharp.fluid.src)

  const images = content.pictures.map(image => {
    return allImages.find(img => img.split('/').pop().includes(encodeURIComponent(image)))
  })

  const openLightbox = (index) => {
    setPhotoIndex(index)
    setIsOpen(true)
  }

  return (
    <section className="flex flex-wrap lg:px-12 xl:px-40 mt-12 -mx-2">
      {content.pictures.map((picture, index) => (
        <div key={index} className=" w-1/2 lg:w-1/3  square relative overflow-hidden cursor-pointer" onClick={() => openLightbox(index)}>
          <Image src={picture} className="absolute h-full w-full p-2"/>
        </div>
      ))}

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
      )}
    </section>
  )
}
